<template>
  <TransitionRoot as="template" :show="cartStore.showCart">
    <Dialog as="div" class="relative z-10" @close="cartStore.closeCart">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-0 flex-col lg:flex-row"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden bg-gray shadow-xl transition-all p-4 w-full min-h-full flex-1 lg:flex-initial lg:p-5 lg:w-[800px] lg:min-h-auto lg:rounded-xl space-y-2.5 lg:space-y-4"
            >
              <DialogTitle class="flex justify-between items-center">
                <h3 class="font-bold text-sm lg:text-[20px]">
                  {{ t('Кошик') }}
                </h3>
                <button
                  id="btn-modal-cart-close"
                  type="button"
                  class="rounded-md text hover:text-primary focus:outline-none"
                  @click="cartStore.closeCart"
                >
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </DialogTitle>

              <template v-if="cartStore.count">
                <div v-loading="loading" class="space-y-2.5 lg:space-y-4">
                  <!-- Products in cart -->
                  <template
                    v-for="item in cartItemsWithPromotions"
                    :key="item.id"
                  >
                    <AppCartItem :item="item" />
                  </template>
                  <nuxt-img
                    v-if="bannerImage"
                    :src="bannerImage?.imageLandscape"
                    class="cursor-pointer w-full object-contain"
                    @click="navigateTo(bannerImage?.url, { external: true })"
                  />
                  <!-- Actions -->
                  <div
                    v-if="cart?.cannotCheckoutOrder"
                    class="text-xs text-red-hot flex flex-wrap justify-end w-full"
                  >
                    {{ t('order_min_cost') }}
                  </div>
                  <div class="grid gap-4 lg:gap-5 lg:grid-cols-[auto_1fr_auto]">
                    <div
                      class="lg:col-start-2 flex items-center justify-between"
                    >
                      <a
                        href="#"
                        class="text-[12px] link-mud-underline"
                        @click.prevent="cartStore.removeAll"
                      >{{ t('Видалити все') }}</a>
                      <AppTotalPrice
                        id="modal-cart-total-price"
                        class="total-price"
                        :price="cart.itemsTotal ? cart.itemsTotal / 100 : 0"
                      />
                    </div>
                    <div class="">
                      <button
                        id="btn-cart-checkout-modal"
                        class="btn-primary total-button"
                        :disabled="cart?.cannotCheckoutOrder"
                        @click="checkout"
                      >
                        {{ t('Оформити замовлення') }}
                      </button>
                    </div>
                    <button
                      type="button"
                      class="border btn-outlet-default text-gray-mud lg:col-start-1 lg:row-start-1 total-button"
                      @click="continueShopping"
                    >
                      {{ t('Продовжити покупки') }}
                    </button>
                  </div>
                </div>
              </template>
              <template v-else>
                <!-- Empty state banner -->
                <AppCartEmpty />
                <nuxt-img
                  v-if="bannerImage"
                  :src="bannerImage?.imageLandscape"
                  class="cursor-pointer w-full object-contain"
                  @click="navigateTo(bannerImage?.url, { external: true })"
                />
                <!-- Products scroll -->
                <div class="!mt-[45px]">
                  <ListWatchedProducts
                    :slides-per-view="2"
                    :breakpoints="{
                      600: {
                        slidesPerView: 3
                      },
                      900: {
                        slidesPerView: 4
                      }
                    }"
                  />
                </div>
              </template>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import Collection from '~/entities/Collection'
import { useCartStore } from '~~/stores/cart'
import { useAuthStore } from '~/stores/auth'

const { t } = useI18n()
const loading = ref(false)
const cartStore = useCartStore()
const { cart } = storeToRefs(cartStore)
const menuStore = useMenuStore()
const route = useRoute()

const { mainCategory } = storeToRefs(menuStore)

const bannerImage = ref({})

const cartItemsWithPromotions = computed(() => {
  if (!cartStore.productPromotionList.length) {
    return cartStore.productList
  }
  const updatedItems = []

  const discountedCodes = new Set()

  // create exist codes in discountedVariants
  cartStore.productPromotionList?.forEach(promotion => {
    promotion?.discountedVariants?.forEach(variant => {
      discountedCodes.add(variant.code)
    })
  })

  cartStore.productList?.forEach(item => {
    const promotion = cartStore.productPromotionList?.find(
      promo => promo?.originalVariant?.code === item?.variant?.code
    )

    if (promotion) {
      // add primary product
      const mainItem = { ...item, children: [] }

      // add discountedVariants as children
      promotion.discountedVariants?.forEach(variant => {
        const discountedItem = cartStore.productList?.find(
          i => i?.variant?.code === variant?.code
        )
        if (discountedItem) {
          mainItem.children.push(discountedItem)
        }
      })

      updatedItems.push(mainItem)
    } else if (!discountedCodes.has(item?.variant?.code)) {
      // without promotion
      updatedItems.push(item)
    }
  })
  return updatedItems
})

onMounted(() => {
  return useApi()
    .banners.getCart()
    .then(res => {
      bannerImage.value = new Collection(res)?.data?.[0]
    })
})

const continueShopping = () => {
  if (route.path?.includes('/checkout')) {
    navigateTo(useLocalePath()(mainCategory.value?.href + '/'))
  }
  return cartStore.closeCart()
}

const checkout = () => {
  cartStore.closeCart()
  if (useAuthStore().loggedInStore) {
    return navigateTo(useLocalePath()('/checkout-store/'))
  }
  return navigateTo(useLocalePath()('/checkout/'))
}
watch(
  () => cart,
  () => {
    loading.value = false
  },
  {
    deep: true
  }
)

provide('loading', loading)

watch(
  () => useRoute().fullPath,
  () => cartStore.showCart && cartStore.closeCart()
)

watch(
  () => cartStore.showCart,
  value => {
    value && useNuxtApp().$gtagEvent.viewCart()
  }
)
</script>

<style lang="postcss" scoped>
.total-price {
  @apply text-[19px] font-bold;
}
.total-button {
  @apply btn text-[12px] uppercase font-bold py-3;
}
</style>
