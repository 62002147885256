<template>
  <div>
    <div
      :id="'product-cart-item-' + variant?.code"
      class="grid grid-cols-[1fr_auto] lg:grid-cols-[auto_auto_auto] gap-[10px] lg:gap-[46px] bg-white items-start lg:items-center min-h-[86px] p-4"
    >
      <div class="flex gap-[14px] items-center">
        <div class="min-w-[60px] flex justify-center">
          <nuxt-img
            v-if="image"
            :src="image"
            format="webp"
            loading="lazy"
            class="max-h-[53px] w-[60px] object-contain"
          />
        </div>
        <div class="grid gap-2.5">
          <div
            class="line-clamp-2 text-[11px] lg:text-[13px] font-bold lg:leading-[17px]"
          >
            {{ variant?.name }}
          </div>
          <div class="text-[11px] lg:text-[12px] text-gray-mud">
            {{ t('Код товару') }}: {{ variant?.code }}
          </div>
          <div v-if="item?.hasDiscountLimit" class="text-red-hot text-[11px]">
            {{ t('Товар з обмеженою знижкою') }}
          </div>
        </div>
      </div>

      <div class="flex gap-[14px] items-center row-span-2 lg:row-span-1">
        <div>
          <div v-if="item?.getDiscount()" class="space-x-1 text-[12px]">
            <span class="line-through decoration-primary">{{
              item?.getFormattedOriginalUnitPrice()
            }}</span>
            <span class="px-1.5 bg-red-hot/20">-{{ item?.getDiscount() }}%</span>
          </div>
          <div
            :id="'cart-price-item-' + variant?.code"
            class="font-bold text-[19px]"
            :class="{ 'text-primary': item?.getDiscount() || item?.children }"
          >
            {{ item?.getFormattedDiscountedUnitPrice() }}
          </div>
        </div>
        <ProductSectionsCommonAmountPicker v-model="quantity" />
        <div
          :id="'cart-total-price-item-' + variant?.code"
          class="text-[13px] lg:text-[19px] font-bold"
        >
          {{ item.getFormattedTotal() }}
        </div>
      </div>

      <!-- Menu -->
      <div class="col-start-2 row-start-1 lg:col-start-3">
        <Menu
          :id="'btn-cart-dots-item-' + variant?.code"
          as="div"
          class="relative inline-block text-left"
        >
          <div>
            <MenuButton>
              <EllipsisHorizontalIcon class="w-[26px] text-gray-mud" />
            </MenuButton>
          </div>

          <app-transitions-dropdown>
            <MenuItems
              class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1 text-[12px]">
                <MenuItem v-slot="{ active }">
                  <a
                    :id="'btn-cart-remove-item-' + variant?.code"
                    href="#"
                    :class="[active ? 'text-primary' : '', 'menu-link']"
                    @click.prevent="removeItem"
                  >
                    <IconsDelete class="w-4" />
                    {{ t('Видалити') }}
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    :id="'btn-cart-add-to-favorites-' + variant?.code"
                    href="#"
                    :class="[active ? 'text-primary' : '', 'menu-link']"
                    @click="addToFavorites"
                  >
                    <IconsHeart
                      class="w-4"
                      :class="isInFavorites && '!text-primary'"
                    />
                    {{
                      !isInFavorites
                        ? t('У список обраного')
                        : t('Видалити з обраного')
                    }}
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </app-transitions-dropdown>
        </Menu>
      </div>
      <AppDialogsWishlist v-model="modalWishlists" />
    </div>
    <AppCartPromotionItem
      v-if="item?.children?.length"
      :item="item.children[0]"
    />
  </div>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { EllipsisHorizontalIcon } from '@heroicons/vue/24/solid'
import debounce from 'lodash/debounce'
import OrderItemEntity from '~~/entities/OrderItem.entity'
import { useCartStore } from '~~/stores/cart'
import { useUserStore } from '~/stores/user'

const { $gtagEvent } = useNuxtApp()

const cartStore = useCartStore()
const { t } = useI18n()
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
})
const loading = inject('loading')
const item = computed(() => new OrderItemEntity(props.item))
const variant = computed(() => item.value?.variant)

const dataTitle =
  'variant-image-preview-' + (variant.value && variant.value?.code)

const { data: image } = useLazyAsyncData(dataTitle, () =>
  variant.value?.fetchMainImage(PRODUCT_IMAGE_FILTER.PRODUCT_ORDER_THUMBNAIL)
)

const quantity = ref(item.value.quantity)
watch(
  () => quantity.value,
  debounce((v, oldV) => {
    if (props.item.children?.length) {
      const calculateChildQuantity = childQuantity => {
        if (childQuantity === v) {
          return v
        }
        return v + (childQuantity - props.item?.quantity)
      }
      return cartStore.changeMultipleQuantity(
        [
          {
            itemId: props.item?.id,
            quantity: v
          },
          {
            itemId: props.item?.children[0]?.id,
            quantity: calculateChildQuantity(props.item?.children[0]?.quantity)
          }
        ],
        v,
        {
          onRequest: () => (loading.value = true),
          onSuccess: () => {
            try {
              if (v > oldV) {
                $gtagEvent.addToCart({
                  ...item.value,
                  quantity: v - oldV
                })
                return $gtagEvent.addToCart({
                  ...item.value.children[0],
                  quantity: v - oldV
                })
              } else {
                $gtagEvent.removeFromCart({
                  ...item.value,
                  quantity: oldV - v
                })
                return $gtagEvent.removeFromCart({
                  ...item.value.children[0],
                  quantity: oldV - v
                })
              }
            } catch (e) {
              console.error(e)
            }
          },
          onFinally: () => (loading.value = false)
        }
      )
    }
    return cartStore.changeQuantity(props.item.id, v, {
      onRequest: () => (loading.value = true),
      onSuccess: () => {
        try {
          return v > oldV
            ? $gtagEvent.addToCart({
              ...item.value,
              quantity: v - oldV
            })
            : $gtagEvent.removeFromCart({
              ...item.value,
              quantity: oldV - v
            })
        } catch (e) {
          console.error(e)
        }
      },
      onFinally: () => (loading.value = false)
    })
  }, 700)
)

const removeItem = async () => {
  loading.value = true
  try {
    $gtagEvent.removeFromCart(item.value)
    if (cartStore.count > 1) {
      if (item.value?.children) {
        $gtagEvent.removeFromCart(item.value.children[0])
        if (cartStore.count === 2) {
          return await cartStore.removeAll()
        }
        return await cartStore.removeMultipleItems({
          items: [item.value?.id, item.value?.children[0]?.id]
        })
      }
      return await cartStore.removeItem(item.value.id)
    }
    return await cartStore.removeAll()
  } finally {
    loading.value = false
  }
}

const openWishlistDialog = () => {
  modalWishlists.value?.open(variant.value)
}

const modalWishlists = ref(null)

const favoritesList = computed(() => useUserStore().wishlists || [])

const isInFavorites = computed(() => {
  return favoritesList.value.some(list => {
    return list.wishlistProducts.find(
      item => item.variant?.id === variant.value?.id
    )
  })
})

const addToFavorites = () => {
  const options = {
    openModal: openWishlistDialog,
    isFetch: false,
    isInFavorites: isInFavorites.value
  }
  useWishlist(variant.value, options)
}
</script>

<style lang="postcss" scoped>
.menu-link {
  @apply px-4 py-2 flex items-center gap-3;
}
</style>
