<template>
  <div
    class="relative text-left text-[12px] space-y-3 bg-white rounded-[3px] p-2.5 pb-0 lg:p-7"
  >
    <div class="text-[13px] font-bold lg:text-[15px]">
      {{ t('У вашому кошику немає товарів') }}
    </div>
    <div class="max-w-[320px]">
      {{ t('empty_cart_description') }}
    </div>
    <NuxtLink
      :to="localePath(mainCategory?.href)"
      class="inline-block btn btn-primary uppercase py-2.5 px-3 w-full lg:w-auto"
      @click="cartStore.closeCart"
    >
      {{ t('Перейти в каталог') }}
    </NuxtLink>

    <nuxt-img
      class="ml-auto lg:absolute lg:right-0 lg:-bottom-2.5"
      src="/images/cart/empty-background.svg"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useCartStore } from '~~/stores/cart'
import { useMenuStore } from '~/stores/menu'
const menuStore = useMenuStore()
const cartStore = useCartStore()
const { t } = useI18n()

const { mainCategory } = storeToRefs(menuStore)
</script>
